import React, { useEffect } from "react"
import { Filter, CenterContent, GalleryItem } from "../components/_index"
import { useMediaQuery, useTheme } from "@material-ui/core"
import * as PropTypes from "prop-types"
import ImageList from "@material-ui/core/ImageList"
import { ImageListItem } from "@material-ui/core"
import { graphql } from "gatsby"
import Grid from "@material-ui/core/Grid"
import SiteMetadata from "../components/SiteMetaData"
import Layout from "../components/Layout"

const GalleryList = (props) => {
  const {
    data: {
      config: { filterComponent },
      galleryItems: { nodes: items },
    },
    pageContext: { filterList },
  } = props

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("sm"))

  const handleScroll = () => {
    localStorage.setItem("scrollPositionY", window.pageYOffset.toString())
  }

  const handleUnload = () => {
    localStorage.clear()
  }

  useEffect(() => {
    const storedScrollPositionY = localStorage.getItem("scrollPositionY")
    if (storedScrollPositionY) {
      window.scrollTo({
        behavior: "smooth",
        left: 0,
        top: Number.parseInt(storedScrollPositionY),
      })
    }
    window.addEventListener("scroll", handleScroll, { passive: true })
    window.addEventListener("beforeunload", handleUnload);
    return () => {
      window.removeEventListener("scroll", handleScroll)
      window.removeEventListener("beforeunload", handleUnload);
    }
  })

  return (
    <Layout>
      <SiteMetadata
        title={`Galerie - ${items[0].category}`}
        description={`Galerie - ${items[0].category}`}
      />
      <CenterContent
        ml={matches ? theme.spacing(2) : 0}
        mr={matches ? theme.spacing(2) : 0}
        mt={0}
        pt={0}
      >
        {items.length >= 1 ? (
          <ImageList
            rowHeight={"auto"}
            gap={matches ? theme.spacing(2) : 0}
            cols={items.length === 1 ? 1 : 2}
          >
            {items.map((item) => (
              <ImageListItem
                key={item.id}
                cols={items.length === 1 ? 1 : matches ? 1 : 2}
                style={{ marginBottom: theme.spacing(2) }}
              >
                <GalleryItem {...item} />
              </ImageListItem>
            ))}
          </ImageList>
        ) : (
          items.map((item) => (
            <Grid item key={item.id} sm={10}>
              <GalleryItem {...item} />
            </Grid>
          ))
        )}
      </CenterContent>
      {/*Floating Action Button*/}
      <Filter data={{ ...filterComponent, items: filterList }} />
    </Layout>
  )
}

GalleryList.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
}

export const query = graphql`
  query($category: String = "Damenringe") {
    config: dataJson {
      filterComponent {
        title
        cancelTitle
        items {
          text
          url
        }
      }
    }
    galleryItems: allContentfulGalleryItem(
      filter: { category: { eq: $category } }
      sort: { fields: createdAt, order: ASC }
    ) {
      nodes {
        id: contentful_id
        title
        category
        hashTags
        images {
          fluid(maxWidth: 1280, maxHeight: 960, quality: 85) {
            ...GatsbyContentfulFluid_withWebp
          }
          title
        }
      }
    }
  }
`

export default GalleryList
